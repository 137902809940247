import html2canvas from "html2canvas";
import moment from "moment";
import { FC, useEffect, useState, useRef } from "react";
import DownloadIcon from "../../assets/images/icons/download.png";
import Calendar from "../../common-components/Calendar";
import TopMenuBar from "../../common-components/TopMenuBar";
import {
  EOrder,
  EOrderSequencing,
  ESearchMatch,
} from "../../core/enums/Search.enums";
import { ICommonSearch } from "../../core/interfaces/SearchRequest.interface";
import { getLocalStorageItem } from "../../core/services/StorageService";
import { IDateRange } from "../../interface/html-elements.interface";
import { IReportUsersList } from "../../interface/report/Report.interface";
import {
  getAndStoreFranchiseDeatils,
  getFranchiserUserDeatils,
} from "../../services/AuthApiService";
import { getReportUsers, downloadReportCSV  } from "../../services/reports/ReportApiService";
import LineChart from "./LineChart";
import ReportUserCard from "./ReportUserCard";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useOutsideClick } from "../../core/services/utilityService";


interface TitleProps {
  title: string;
}
const Reports: FC<TitleProps> = ({ title }) => {
  const [selectedUsersChartData, setSelectedUsersChartData] = useState([
    {},
    {},
    {},
    {},
  ]);
  const [selectedAllUsersData, setSelectedAllUsersData] = useState([
    {},
    {},
    {},
    {},
  ]);
  const [reportUsersList, setReportUsersList] = useState<IReportUsersList[]>(
    []
  );
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: moment()
      .subtract(1, "months")
      .endOf("month")
      .format("DD/MM/YYYY"),
    endDate: moment().format("DD/MM/YYYY"),
  });
  const [toggleDownloadDropDown, setToggleDownloadDropDown] = useState<boolean>(false)
  const franchiseRefCode = useRef<string>("");
  const userRefCode = useRef<string>("");
  const downloadPopUpRef = useRef<HTMLDivElement>(null);


  const userProfileData = getLocalStorageItem("userProfile");
      const franchiseId = userProfileData?.franchise_id


  useOutsideClick(downloadPopUpRef, () => setToggleDownloadDropDown(false));


  const saveAsPNG = () => {
    const element = document.getElementById("element-to-capture");
    if (element) {
      html2canvas(element, {
        ignoreElements: (element) => 
          element.classList.contains('ignore-for-screenshot')
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "reports.png";
        link.click();
      });
    }
    setToggleDownloadDropDown(false);
  };


  const initializeSearch = () => {
    let search: any = {
      page: {
        no: 1,
        // size: 10,
      },
      query: {
        filter: [
          // {
          //     fields: ["referral_code"],
          //     search: franchiseRefCode,
          //     search_match: ESearchMatch.EXACT,
          // },
        ],
        search: {} as ICommonSearch,
      },
      sort: [
        {
          field: "created_on",
          direction: EOrder.DESC,
          order_sequencing: EOrderSequencing.ACTIVE,
        },
      ],
    };

    if (franchiseRefCode.current) {
      search.query.filter.push({
        fields: ["referral_code"],
        search: franchiseRefCode.current ? franchiseRefCode.current : "",
        search_match: ESearchMatch.EXACT,
      });
    }
    if (userRefCode.current) {
      search.query.filter.push({
        fields: ["user_referral_code"],
        search: userRefCode.current ? userRefCode.current : "",
        search_match: ESearchMatch.EXACT,
      });
    }
    return search;
  };

  const DownloadReportAsCSV = async () => {
    let searchRequest = initializeSearch();

    searchRequest.query.filter = searchRequest.query.filter.filter(
      (filterItem: { fields: string | string[]; }) => !filterItem.fields.includes("referral_code")
    );

    searchRequest.query.filter.push({
      fields: ["franchise_id"],
      search: franchiseId,
      search_match: ESearchMatch.EXACT,
    })
    downloadReportCSV(searchRequest).then((responses) => {
      download(responses);
    });
  
    setToggleDownloadDropDown(false);
  };

  const download = async (response: Blob) => {
      const blob = new Blob([response], {
        type: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls , .xlsx",
      });
    saveAs(blob, 'reports.xlsx');
  };


  const handleGetReportUsers = async () => {
    try {
      await getAndStoreFranchiseDeatils();
      
      if (userProfileData.user_type === "FRANCHISE_ADMIN") {
        franchiseRefCode.current = userProfileData?.franchise_ref_code;
        userRefCode.current = "";
      } else {
        userRefCode.current = userProfileData?.referral_code;
        franchiseRefCode.current = "";
      }
      const searchRequest = initializeSearch();
      const res = await getReportUsers(searchRequest);
      if (res.response.content.length) {
        setReportUsersList(res.response.content);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleGetReportUsers();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <TopMenuBar  hideSearchBar={true} />
        <div className="reports-view p-3" id="element-to-capture">
          <div className="row pb-3">
            <div className="col-12" >
              <div className="d-md-flex align-items-center justify-content-between">
                <h1 className="font-16 fw-500 mb-0">Sales Report</h1>
                <div className="d-sm-flex">
                  <div className="ignore-for-screenshot grid justify-center align-center"
                    style={{ position: "relative" }}>
                    <button className="download-report-btn  " onClick={() => setToggleDownloadDropDown(!toggleDownloadDropDown)}>
                      <img src={DownloadIcon} alt="Download" className="download-icon me-1" loading="lazy" />
                      Download Report
                    </button>
                    {
                      toggleDownloadDropDown ? (
                        <div  
                       ref={downloadPopUpRef} 
                        className="bg-white border border-gray-500 rounded-lg flex justify-center align-center"
                          style={{ position: "absolute", zIndex: 9999, width: "220px", right: "0" }}>
                          <div className="border border-solid border-gray-400 text-start px-2 CursorPointer" onClick={DownloadReportAsCSV}> Download Card Data ( csv.)</div>
                          <div className="border border-solid border-gray-400 text-start px-2 CursorPointer" onClick={saveAsPNG}> Download as Image ( png.)</div>
                        </div>
                      ) : null
                    }
                  </div>
                  <div className="ps-sm-4">
                    <Calendar
                      className="calender"
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  className="row pt-2">
            <div className="col-12 col-md-5 col-xxl-4 mb-2 mb-md-0">
              <div className="report-user">
                {[{}, {}, {}, {}]?.map((data, index) => (
                  <ReportUserCard
                    reportUsersList={reportUsersList}
                    dateRange={dateRange}
                    reportIndex={index}
                    setSelectedUsersChartData={setSelectedUsersChartData}
                    selectedUsersChartData={selectedUsersChartData}
                    selectedAllUsersData={selectedAllUsersData}
                    setSelectedAllUsersData={setSelectedAllUsersData}
                  />
                ))}
              </div>
            </div>
            <div className="col-12 col-md-7 col-xxl-8">
              <div className="user-data">
                <LineChart chartData={selectedUsersChartData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
